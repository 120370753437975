import React from "react"
import styled from "styled-components"

import FooterRightForm from "./FooterRightForm"
import FooterRightSocial from "./FooterRightSocial"
import { B2OpenSansWhiteBold, buttonOneWhite } from "../../styles/helpers"
import LogoCCA from "../Logos/LogoCCA"

const FooterCentreSection = styled.div`
  width: 100%;
  margin-bottom: 5rem;

  @media (min-width: 768px) {
    width: 20%;
    margin-bottom: 0;
  }

  @media (min-width: 1025px) {
    width: 30%;
  }

  .logo-wrapper {
    width: 100%;
    max-width: 20rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
  }
`

const FooterCenter = () => {
  return (
    <FooterCentreSection>
      <div className="logo-wrapper">
        <LogoCCA />
      </div>
    </FooterCentreSection>
  )
}

export default FooterCenter
