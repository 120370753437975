import React from "react"
import styled from "styled-components"
import MobileSubMenuItem from "./MobileSubMenuItem"

const MobileSubMenu = ({ subActive }) => {
  return (
    <MobileSubMenuStyled subactive={subActive} sublenght={10}>
      <MobileSubMenuItem
        item={{
          url: "/ministries/white-fields-kids",
          label: "Kids",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/white-fields-youth",
          label: "Youth",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/young-adults",
          label: "Young Adults",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/body-life-groups",
          label: "Body Life Groups",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/sword-trowel-mens-ministry",
          label: "Men's Ministry",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/one-thing-ladies-ministry",
          label: "Ladies' Ministry",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/worship",
          label: "Worship",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/start-study-for-new-believers",
          label: "Start Bible Study",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/calvary-next-school-of-ministry",
          label: "Calvary Next",
        }}
      />
      <MobileSubMenuItem
        item={{
          url: "/ministries/care-connect",
          label: "Care & Connect",
        }}
      />
    </MobileSubMenuStyled>
  )
}

const MobileSubMenuStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: ${props =>
    props.subactive ? `calc((4.75rem * ${props.sublenght}))` : `0rem`};
  transition: all 0.3s ease-out;
  opacity: ${props => (props.subactive ? 1 : 0)};
  visibility: ${props => (props.subactive ? "visible" : "hidden")};
`

export default MobileSubMenu
